// roles
export const ROLE_SA = "SUPER_ADMIN";
export const ROLE_CT = "COUNTRY_TREASURER";
export const ROLE_CC = "CENTER_COORDINATOR";
export const ROLE_CFC = "CENTER_FINANCE_CONTROLLER";
export const ROLE_DC = "DONATION_COLLECTOR";
export const ROLE_SG = "SPECIAL_GUEST";
export const ROLE_SDC = "SPECIAL_DONATION_COLLECTOR";

export const currencyDefault = "INR";

export const countryIndiaDefault = "India";

export const idTypes = {
  pan: { label: "PAN Card", value: "Pan Card" },
  aadhar: { label: "Aadhar Card", value: "Aadhar Card" },
  dl: { label: "Driving License", value: "Driving License" },
  passport: { label: "Passport", value: "Passport" },
  ration: { label: "Ration Card", value: "Ration Card" },
  voterId: { label: "Voter ID", value: "Voter Id" },
};

export const idTypeByName = Object.fromEntries(
  Object.values(idTypes).map((item) => [item.value, item])
);

export const paymentModes = {
  cash: { label: "Cash", value: "CASH" },
  cheque: { label: "Cheque", value: "CHEQUE" },
  dd: { label: "Demand Draft", value: "DEMAND_DRAFT" },
  credit: { label: "Credit Card", value: "CREDIT_CARD" },
  debit: { label: "Debit Card", value: "DEBIT_CARD" },
  eft: { label: "EFT", value: "EFT" },
  ecs: { label: "ECS", value: "ECS" },
  upi: { label: "UPI", value: "UPI" },
};

export const nonHqPaymentModes = [
  paymentModes.cash,
  paymentModes.cheque,
  paymentModes.dd,
  paymentModes.credit,
  paymentModes.debit,
  paymentModes.eft,
  paymentModes.upi,
];
 
export const hqPaymentModes = [ ...nonHqPaymentModes, paymentModes.ecs ]

export const paymentModeByName = Object.fromEntries(
  Object.values(paymentModes).map((item) => [item.value, item])
);

export const depositPaymentModes = {
  cash: { label: "Cash", value: "CASH" },
  cheque: { label: "Cheque", value: "CHEQUE" },
  dd: { label: "Demand Draft", value: "DEMAND_DRAFT" },
};

export const depositPaymentModeByName = Object.fromEntries(
  Object.values(depositPaymentModes).map((item) => [item.value, item])
);

export const challanTypes = {
  cash: { label: "Cash", value: "CASH_TYPE" },
  chequeOrDd: { label: "Cheque / DD", value: "CHEQUE_OR_DD_TYPE" },
};

export const challanTypeByName = Object.fromEntries(
  Object.values(challanTypes).map((item) => [item.value, item])
);

export const generalStatuses = {
  active: { label: "Active", value: "ACTIVE" },
  inactive: { label: "Inactive", value: "INACTIVE" },
};

export const generalStatusesTb = [
  { label: "All", value: ["ACTIVE", "INACTIVE"] },
  { label: "Active", value: "ACTIVE" },
  { label: "Inactive", value: "INACTIVE" },
];

export const printerOptionses = [
  { label: "Thermal", value: "Thermal Printer" },
  { label: "Normal", value: "Normal Printer" },
];

export const NORMAL_PRINTER = "Normal Printer";

export const THERMAL_PRINTER = "Thermal Printer";

export const generalStatusByName = Object.fromEntries(
  Object.values(generalStatuses).map((item) => [item.value, item])
);

export const passportOptionses = {
  submitted: { label: "Submitted", value: "Submitted" },
  fallowup: {
    label: "Follow-up for submission",
    value: "Follow-up for submission",
  },
};

export const bankAccStatuses = {
  active: { label: "Active", value: "ACTIVE" },
  inactive: { label: "Inactive", value: "INACTIVE" },
  closed: { label: "Closed", value: "CLOSED" },
};

export const bankAccStatusByName = Object.fromEntries(
  Object.values(bankAccStatuses).map((item) => [item.value, item])
);

export const donationStatuses = {
  pendingDc: { label: "Pending with DC", value: "PENDING_DC" },
  pendingCfc: { label: "Pending with CFC", value: "PENDING_WITH_CFC" },
  toBeDeposited: { label: "To be deposited", value: "TO_BE_DEPOSITED" },
  deposited: { label: "Deposited", value: "DEPOSITED" },
  cancelled: { label: "Cancelled", value: "CANCELLED" },
};

export const addDonationHqCenterStatuses = [
  donationStatuses.pendingDc,
  donationStatuses.deposited,
  donationStatuses.cancelled,
];

export const addDonationMainCenterStatuses = [
  donationStatuses.pendingDc,
  donationStatuses.cancelled,
];

export const donationStatusByName = Object.fromEntries(
  Object.values(donationStatuses).map((item) => [item.value, item])
);

export const sessionStatuses = {
  live: { label: "Live", value: "LIVE_SESSION" },
  pendingDc: { label: "Pending with DC", value: "PENDING_WITH_DC" },
  submittedCfc: { label: "Submitted to CFC", value: "SUBMITTED_TO_CFC" },
  rejected: { label: "Rejected", value: "REJECTED" },
  confirmed: { label: "Confirmed", value: "CONFIRMED" },
};

export const sessionStatusByName = Object.fromEntries(
  Object.values(sessionStatuses).map((item) => [item.value, item])
);

export const batchStatuses = {
  toBeDeposited: { label: "To be deposited", value: "TO_BE_DEPOSITED" },
  deposited: { label: "Deposited", value: "DEPOSITED" },
};

export const batchStatusByName = Object.fromEntries(
  Object.values(batchStatuses).map((item) => [item.value, item])
);

export const receiptStatuses = {
  confirmed: { label: "Confirmed", value: "CONFIRMED_RECEIPT" },
  cancelled: { label: "Cancelled", value: "CANCELLED_RECEIPT" },
};

export const receiptStatusByName = Object.fromEntries(
  Object.values(receiptStatuses).map((item) => [item.value, item])
);

export const sortFields = {
  id: "ID",
  name: "NAME",
  code: "CODE",
  firstName: "FIRST_NAME",
  city: "CITY",
  state: "STATE",
  country: "COUNTRY",
  transactionStatus: "TRANSACTION_STATUS",
  dcId: "DONATION_COLLECTOR_ID",
  centerId: "CENTER_ID",
  timeSubmitted: "TIME_SUBMITTED",
  startDate: "START_DATE",
  endDate: "END_DATE",
  sessionStatus: "SESSION_STATUS",
  cfcId: "CENTER_FINANACE_CONTROLLER_ID",
  amount: "AMOUNT",
  donationDate: "TRANSACTION_DATE",
  createdDate: "CREATED_DATE",
  citizenship: "CITIZENSHIP",
  emailId: "EMAIL_ADDRESS",
  mobileNo: "MOBILE_NUMBER",
  cfcName: "CENTER_FINANCE_CONTROLLER_NAME",
  observation: "OBSERVATION",
  totalTransactionCount: "TRANSACTION_COUNT",
  accountName: "ACCOUNT_NAME",
  bankName: "BANK_NAME",
  branch: "BRANCH",
  accountType: "ACCOUNT_TYPE",
  dcName: "DONATION_COLLECTOR_NAME",
  settlementSlip: "SETTLEMENT_SLIP",
  settlementDate: "SETTLEMENT_DATE",
  settlementAmount: "SETTLEMENT_AMOUNT",
  settlementStatus: "CARD_SETTLEMENT_STATUS",
  accountNumber: "ACCOUNT_NUMBER",
  ifscCode: "IFSC_CODE",
  micrCode: "MICR_CODE",
};

export const sortOrders = {
  asc: "ASCENDING",
  desc: "DESCENDING",
};

export const donorProfileFilters = {
  abhyasi: "ABHYASI_ID",
  idType: "IDENTIFICATION_TYPE",
  abhyasiIdtype: "ABHYASI_ID_AND_IDENTIFICATION_TYPE",
};

export const citizenshipFilters = {
  india: { label: "India", value: "INDIAN" },
  overseas: { label: "Overseas", value: "NON_INDIAN" },
};

export const citizenshipFilterByName = Object.fromEntries(
  Object.values(citizenshipFilters).map((item) => [item.value, item])
);

export const accountTypes = {
  savings: { label: "Savings", value: "SAVINGS" },
  current: { label: "Current", value: "CURRENT" },
};

export const accountTypeByName = Object.fromEntries(
  Object.values(accountTypes).map((item) => [item.value, item])
);

export const centerTypes = {
  main: { label: "Main", value: "MAIN_CENTER" },
  hq: { label: "HQ", value: "HEAD_QUARTER" },
};

export const centerTypeByName = Object.fromEntries(
  Object.values(centerTypes).map((item) => [item.value, item])
);

export const otherBankOption = { label: "Others", value: "Others" };

export const otherForCenterOption = { label: "Others", value: "Others" };

export const cashLimit = 2000;

export const cardSettlementStatuses = {
  dcPending: { label: "Pending with DC", value: "DC_PENDING" },
  cfcPending: { label: "Pending with CFC", value: "CFC_PENDING" },
  confirmed: { label: "Confirmed Settlement", value: "CONFIRMED_SETTLEMENT" },
  rejected: { label: "Rejected Settlement", value: "REJECTED_SETTLEMENT" },
};

export const cardSettlementStatusByName = Object.fromEntries(
  Object.values(cardSettlementStatuses).map((item) => [item.value, item])
);

export const banks = [
  {
    label: "Abhyudaya Co-operative Bank Ltd",
    value: "Abhyudaya Co-operative Bank Ltd",
  },
  { label: "ABN AMRO Bank N.V", value: "ABN AMRO Bank N.V" },
  {
    label: "Abu Dhabi Commercial Bank Ltd",
    value: "Abu Dhabi Commercial Bank Ltd",
  },
  {
    label: "Ahmedabad Mercantile Co-Op Bank Ltd",
    value: "Ahmedabad Mercantile Co-Op Bank Ltd",
  },
  { label: "Allahabad Bank", value: "Allahabad Bank" },
  {
    label: "Amanath Co-operative Bank Ltd",
    value: "Amanath Co-operative Bank Ltd",
  },
  { label: "American Express Bank", value: "American Express Bank" },
  { label: "Andhra Bank", value: "Andhra Bank" },
  {
    label: "Andhra Pradesh Mahesh Co-Op Urban Bank Ltd",
    value: "Andhra Pradesh Mahesh Co-Op Urban Bank Ltd",
  },
  { label: "Antwerp Diamond Bank", value: "Antwerp Diamond Bank" },
  { label: "Arab Bangladesh Bank", value: "Arab Bangladesh Bank" },
  { label: "Axis Bank", value: "Axis Bank" },
  {
    label: "Banc Intesa Banca Commerciale Italiana",
    value: "Banc Intesa Banca Commerciale Italiana",
  },
  { label: "Banca di Roma", value: "Banca di Roma" },
  { label: "Banca Popolare di Vicenza", value: "Banca Popolare di Vicenza" },
  {
    label: "Banca Populare Di Verona E Novara",
    value: "Banca Populare Di Verona E Novara",
  },
  {
    label: "Banco Bilbao Vizcaya Argentaria",
    value: "Banco Bilbao Vizcaya Argentaria",
  },
  { label: "Banco de Sabadell", value: "Banco de Sabadell" },
  {
    label: "Bangalore city cooperative bank",
    value: "Bangalore city cooperative bank",
  },
  {
    label: "Bank International Indonesia",
    value: "Bank International Indonesia",
  },
  { label: "Bank of America", value: "Bank of America" },
  { label: "Bank of Bahrain and Kuwait", value: "Bank of Bahrain and Kuwait" },
  { label: "Bank of Baroda", value: "Bank of Baroda" },
  { label: "Bank of Ceylon", value: "Bank of Ceylon" },
  { label: "Bank of India", value: "Bank of India" },
  { label: "Bank of Maharashtra", value: "Bank of Maharashtra" },
  { label: "Bank of Nova Scotia", value: "Bank of Nova Scotia" },
  { label: "Bank of Rajasthan", value: "Bank of Rajasthan" },
  {
    label: "Bank of Tokyo Mitsubishi UFJ",
    value: "Bank of Tokyo Mitsubishi UFJ",
  },
  { label: "Barclays Bank", value: "Barclays Bank" },
  {
    label: "Bassein Catholic Co-operative Bank Limited",
    value: "Bassein Catholic Co-operative Bank Limited",
  },
  {
    label: "Bayerische Hypo und Vereinsbank",
    value: "Bayerische Hypo und Vereinsbank",
  },
  {
    label: "Bharat Co-operative Bank (Mumbai) Ltd",
    value: "Bharat Co-operative Bank (Mumbai) Ltd",
  },
  {
    label: "Bharati Sahakari Bank Limited",
    value: "Bharati Sahakari Bank Limited",
  },
  { label: "BNP Paribas", value: "BNP Paribas" },
  {
    label: "Bombay Mercantile Co-operative Bank Limited",
    value: "Bombay Mercantile Co-operative Bank Limited",
  },
  {
    label: "BPU Banca -Banche Popolari Unite",
    value: "BPU Banca -Banche Popolari Unite",
  },
  { label: "Caixa Geral de Depositos", value: "Caixa Geral de Depositos" },
  { label: "Calyon Bank", value: "Calyon Bank" },
  { label: "Canara Bank", value: "Canara Bank" },
  { label: "Catholic Syrian Bank", value: "Catholic Syrian Bank" },
  { label: "Central Bank of India", value: "Central Bank of India" },
  {
    label: "Charminar Coop.Urban Bank Ltd",
    value: "Charminar Coop.Urban Bank Ltd",
  },
  { label: "ChinaTrust Commercial Bank", value: "ChinaTrust Commercial Bank" },
  { label: "Citibank", value: "Citibank" },
  {
    label: "Citizen Credit Co-operative Bank Ltd",
    value: "Citizen Credit Co-operative Bank Ltd",
  },
  { label: "City Union Bank", value: "City Union Bank" },
  { label: "Commerzbank", value: "Commerzbank" },
  { label: "Commonwealth Bank", value: "Commonwealth Bank" },
  { label: "Corporation Bank", value: "Corporation Bank" },
  {
    label: "Cosmos Co-operative Urban Bank Ltd",
    value: "Cosmos Co-operative Urban Bank Ltd",
  },
  {
    label: "Credit Industriel et Commercial",
    value: "Credit Industriel et Commercial",
  },
  { label: "DBS Bank", value: "DBS Bank" },
  { label: "Dena Bank", value: "Dena Bank" },
  { label: "DEPFA Bank", value: "DEPFA Bank" },
  { label: "Deutsche Bank", value: "Deutsche Bank" },
  { label: "Development Credit Bank", value: "Development Credit Bank" },
  { label: "Dhanalakshmi Bank", value: "Dhanalakshmi Bank" },
  {
    label: "Dombivli Nagari Sahakari Bank Ltd",
    value: "Dombivli Nagari Sahakari Bank Ltd",
  },
  { label: "Dresdner Bank", value: "Dresdner Bank" },
  {
    label: "DZ Bank AG Deutsche Zentral - Genossenschafts Bank",
    value: "DZ Bank AG Deutsche Zentral - Genossenschafts Bank",
  },
  {
    label: "Emirates Bank International",
    value: "Emirates Bank International",
  },
  { label: "Everest Bank", value: "Everest Bank" },
  { label: "Federal Bank", value: "Federal Bank" },
  { label: "First Rand Bank", value: "First Rand Bank" },
  { label: "Fortis Bank", value: "Fortis Bank" },
  {
    label: "Goa Urban Co-operative Bank Limited",
    value: "Goa Urban Co-operative Bank Limited",
  },
  {
    label: "Greater Bombay Co-operative Bank Limited",
    value: "Greater Bombay Co-operative Bank Limited",
  },
  { label: "Hatton National Bank", value: "Hatton National Bank" },
  { label: "HDFC Bank", value: "HDFC Bank" },
  {
    label: "HSBC (Hongkong  and Shanghai Banking Corporation)",
    value: "HSBC (Hongkong  and Shanghai Banking Corporation)",
  },
  { label: "HSH Nordbank", value: "HSH Nordbank" },
  { label: "ICICI Bank", value: "ICICI Bank" },
  { label: "IDBI Bank Ltd", value: "IDBI Bank Ltd" },
  { label: "Indian Bank", value: "Indian Bank" },
  {
    label: "Indian Mercantile Co-op Bank Ltd.",
    value: "Indian Mercantile Co-op Bank Ltd.",
  },
  { label: "Indian Overseas Bank", value: "Indian Overseas Bank" },
  { label: "IndusInd Bank", value: "IndusInd Bank" },
  { label: "ING Vysya Bank", value: "ING Vysya Bank" },
  {
    label: "Jalgaon Janata Sahakari Bank Ltd.",
    value: "Jalgaon Janata Sahakari Bank Ltd.",
  },
  { label: "Jammu and Kashmir Bank", value: "Jammu and Kashmir Bank" },
  {
    label: "Janakalyan Sahakari Bank Ltd.",
    value: "Janakalyan Sahakari Bank Ltd.",
  },
  {
    label: "Janalaxmi Co-operative Bank Ltd.",
    value: "Janalaxmi Co-operative Bank Ltd.",
  },
  { label: "Janata Sahakari Bank Ltd.", value: "Janata Sahakari Bank Ltd." },
  { label: "JPMorgan Chase Bank", value: "JPMorgan Chase Bank" },
  { label: "K.B.C. Bank N.V.", value: "K.B.C. Bank N.V." },
  {
    label: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank Ltd.",
    value: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank Ltd.",
  },
  {
    label: "Kalupur Commercial Coop.Bank Ltd",
    value: "Kalupur Commercial Coop.Bank Ltd",
  },
  {
    label: "Kalyan Janata Sahakari Bank Ltd.",
    value: "Kalyan Janata Sahakari Bank Ltd.",
  },
  {
    label: "Karad Urban Co-operative Bank Ltd.",
    value: "Karad Urban Co-operative Bank Ltd.",
  },
  { label: "Karnataka Bank", value: "Karnataka Bank" },
  { label: "Karur Vysya Bank", value: "Karur Vysya Bank" },
  { label: "Kotak Mahindra Bank", value: "Kotak Mahindra Bank" },
  { label: "Krung Thai Bank", value: "Krung Thai Bank" },
  {
    label: "Landesbank Baden - Wurttemberg",
    value: "Landesbank Baden - Wurttemberg",
  },
  { label: "Laxmi Vilas Bank", value: "Laxmi Vilas Bank" },
  {
    label: "Madhavpura Mercantile Co-Op Bank Ltd.",
    value: "Madhavpura Mercantile Co-Op Bank Ltd.",
  },
  {
    label: "Mahanagar Co-operative Bank Ltd.",
    value: "Mahanagar Co-operative Bank Ltd.",
  },
  {
    label: "Mapusa Urban Co-operative Bank of Goa Ltd.",
    value: "Mapusa Urban Co-operative Bank of Goa Ltd.",
  },
  { label: "Mashreq Bank", value: "Mashreq Bank" },
  {
    label: "Mehsana Urban Co-Op Bank Ltd.",
    value: "Mehsana Urban Co-Op Bank Ltd.",
  },
  { label: "Mizuho Corporate Bank", value: "Mizuho Corporate Bank" },
  { label: "Monte Dei Paschi Di Sienna", value: "Monte Dei Paschi Di Sienna" },
  {
    label: "Nagar Urban Co-operative Bank Ltd.",
    value: "Nagar Urban Co-operative Bank Ltd.",
  },
  {
    label: "Nagpur Nagrik Sahakari Bank Ltd.",
    value: "Nagpur Nagrik Sahakari Bank Ltd.",
  },
  { label: "Nainital Bank Ltd", value: "Nainital Bank Ltd" },
  {
    label: "Nasik Merchants Co-operative Bank Ltd.",
    value: "Nasik Merchants Co-operative Bank Ltd.",
  },
  { label: "National Bank Australia", value: "National Bank Australia" },
  { label: "Natixis", value: "Natixis" },
  {
    label: "New India Co-operative Bank Ltd.",
    value: "New India Co-operative Bank Ltd.",
  },
  {
    label: "NKGSB Co-operative Bank Ltd.",
    value: "NKGSB Co-operative Bank Ltd.",
  },
  {
    label: "Nutan Nagarik Sahakari Bank Ltd.",
    value: "Nutan Nagarik Sahakari Bank Ltd.",
  },
  { label: "Oman International Bank", value: "Oman International Bank" },
  { label: "Oriental Bank of Commerce", value: "Oriental Bank of Commerce" },
  {
    label: "Parsik Janata Sahakari Bank Ltd.",
    value: "Parsik Janata Sahakari Bank Ltd.",
  },
  { label: "Pravara Sahakari Bank Ltd", value: "Pravara Sahakari Bank Ltd" },
  { label: "Promsvyaz bank", value: "Promsvyaz bank" },
  {
    label: "Punjab and Maharashtra Co-operative Bank Ltd",
    value: "Punjab and Maharashtra Co-operative Bank Ltd",
  },
  { label: "Punjab and Sind Bank", value: "Punjab and Sind Bank" },
  { label: "Punjab National Bank", value: "Punjab National Bank" },
  {
    label: "Raiffeisen Zentral Bank Osterreich",
    value: "Raiffeisen Zentral Bank Osterreich",
  },
  {
    label: "Rajkot Nagrik Sahakari Bank Ltd",
    value: "Rajkot Nagrik Sahakari Bank Ltd",
  },
  { label: "Ratnagar Bank", value: "Ratnagar Bank" },
  { label: "Royal bank of Canada", value: "Royal bank of Canada" },
  {
    label: "Rupee Co-operative Bank Ltd",
    value: "Rupee Co-operative Bank Ltd",
  },
  {
    label: "Sangli Urban Co-operative Bank Ltd",
    value: "Sangli Urban Co-operative Bank Ltd",
  },
  { label: "Sanpaolo IMI Bank", value: "Sanpaolo IMI Bank" },
  { label: "Saqib Saeed Qureshi", value: "Saqib Saeed Qureshi" },
  {
    label: "Saraswat Co-operative Bank Ltd",
    value: "Saraswat Co-operative Bank Ltd",
  },
  {
    label: "Sardar Bhiladwala Pardi Peoples Coop Bank Ltd",
    value: "Sardar Bhiladwala Pardi Peoples Coop Bank Ltd",
  },
  {
    label: "SBI Commercial and International Bank",
    value: "SBI Commercial and International Bank",
  },
  {
    label: "Shamrao Vithal Co-operative Bank Ltd",
    value: "Shamrao Vithal Co-operative Bank Ltd",
  },
  { label: "Shikshak Sahakari Bank Ltd", value: "Shikshak Sahakari Bank Ltd" },
  { label: "Shinhan Bank", value: "Shinhan Bank" },
  {
    label: "Solapur Janata Sahakari Bank Ltd",
    value: "Solapur Janata Sahakari Bank Ltd",
  },
  { label: "Sonali Bank", value: "Sonali Bank" },
  { label: "South Indian Bank Ltd", value: "South Indian Bank Ltd" },
  { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
  {
    label: "State Bank of Bikaner and Jaipur",
    value: "State Bank of Bikaner and Jaipur",
  },
  { label: "State Bank of Hyderabad", value: "State Bank of Hyderabad" },
  { label: "State Bank of India", value: "State Bank of India" },
  { label: "State Bank of Indore", value: "State Bank of Indore" },
  { label: "State Bank of Mauritius", value: "State Bank of Mauritius" },
  { label: "State Bank of Mysore", value: "State Bank of Mysore" },
  { label: "State Bank of Patiala", value: "State Bank of Patiala" },
  { label: "State Bank of Travancore", value: "State Bank of Travancore" },
  {
    label: "Surat Peoples Coop Bank Ltd",
    value: "Surat Peoples Coop Bank Ltd",
  },
  { label: "Syndicate Bank", value: "Syndicate Bank" },
  { label: "Tamil Nadu Mercantile Bank", value: "Tamil Nadu Mercantile Bank" },
  {
    label: "Thane Bharat Sahakari Bank Ltd",
    value: "Thane Bharat Sahakari Bank Ltd",
  },
  {
    label: "Thane Janata Sahakari Bank Ltd",
    value: "Thane Janata Sahakari Bank Ltd",
  },
  {
    label: "The Akola Janata Com.Co-operative Bank Ltd",
    value: "The Akola Janata Com.Co-operative Bank Ltd",
  },
  {
    label: "The Akola Urban Co-operative Bank Ltd",
    value: "The Akola Urban Co-operative Bank Ltd",
  },
  { label: "The Bank of New York", value: "The Bank of New York" },
  {
    label: "The Kapol Co-operative Bank Ltd",
    value: "The Kapol Co-operative Bank Ltd",
  },
  {
    label: "The Karnataka State Co-Operative Apex Bank Ltd",
    value: "The Karnataka State Co-Operative Apex Bank Ltd",
  },
  {
    label: "The Khamgaon Urban Co-operative Bank Ltd",
    value: "The Khamgaon Urban Co-operative Bank Ltd",
  },
  { label: "UBS", value: "UBS" },
  { label: "UCO Bank", value: "UCO Bank" },
  { label: "Uni Credito Italiano", value: "Uni Credito Italiano" },
  { label: "Union Bank of India", value: "Union Bank of India" },
  { label: "United Bank of India", value: "United Bank of India" },
  {
    label: "Vasavi Coop Urban Bank LImited",
    value: "Vasavi Coop Urban Bank LImited",
  },
  { label: "Vijaya Bank", value: "Vijaya Bank" },
  { label: "Vnesheconombank", value: "Vnesheconombank" },
  { label: "VTB India", value: "VTB India" },
  { label: "Wachovia Bank", value: "Wachovia Bank" },
  {
    label: "Westpac Banking Corporation",
    value: "Westpac Banking Corporation",
  },
  { label: "Wori Bank", value: "Wori Bank" },
  { label: "Yes Bank", value: "Yes Bank" },
  {
    label: "Zoroastrian Co-operative Bank Ltd",
    value: "Zoroastrian Co-operative Bank Ltd",
  },
  { label: "Zurcher Kantonalbank", value: "Zurcher Kantonalbank" },
  otherBankOption,
];

export const MAINTENANCE_PROJECT_CODE = "dms";
